import { Expose } from "class-transformer";
import { AchievementHistoryOffer } from "./achievementHistoryOffer";

export class RecommendPopup {
  @Expose({ name: "groupId" })
  groupId: number;

  @Expose({ name: "recommendText" })
  recommendText: string;

  @Expose({ name: "achievementText" })
  achievementText: string;

  @Expose({ name: "offerListBgColor" })
  offerListBgColor: string;

  @Expose({ name: "achievementTextColor" })
  achievementTextColor: string;

  @Expose({ name: "achievementListBgColor" })
  achievementListBgColor: string;

  @Expose({ name: "recommendTextColor" })
  recommendTextColor: string;

  @Expose({ name: "recommendTextColor" })
  pointNumberColor: string;

  @Expose({ name: "offerIds" })
  offerIds: { offerIds: number[] };

  @Expose({ name: "achievementHistoryOffers" })
  achievementHistoryOffers: AchievementHistoryOffer[];

  @Expose({ name: "totalUserOfferPoint" })
  totalUserOfferPoint: number;
}
